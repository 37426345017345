import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {hide, unregisterModal} from "../../features/modal/modalSlice";
import styled from "styled-components";
import Icon from "../Icon";
import CloseIcon from "../Icons/CloseIcon";

const Modal = () => {

    const modal = useSelector(state => state.modal)
    const dispatch = useDispatch()
    const app = useSelector(state => state.app)

    const checkVisible = () => {
        return modal.filter(item => item.isOpen && true).length > 0
    }

    const handleHide = (name) => {
        dispatch(unregisterModal(name))
        dispatch(hide(name))
    }

    const handleClose = (name) => {
        handleHide(name)
    }

    const renderContent = (item) => {
        if(item.component) {
            if(typeof item.component === 'string') return item.component
            return <item.component data={item.props} {...item.data} hideModal={() => handleHide(item.name)} />
        }
    }

    const calculateModalWidth = (size) => {
        switch (size) {
            case 'lg':
                return 900
            case 'md':
                return 800
            case 'sm':
                return 700
            default:
                return 700
        }
    }

    return (
        <Container isVisible={checkVisible()}>
            {modal.map(item => item.isOpen && (
                <ModalWrapper width={calculateModalWidth(app.fontSize)}>
                    <ModalHeader>
                        <Title>
                            {item.title}
                        </Title>
                        <Icon onClick={() => handleClose(item.name)} icon={CloseIcon} tooltip={'Закрыть'} />
                    </ModalHeader>
                    <ModalContent>
                        {renderContent(item)}
                    </ModalContent>
                    {/*{item.footerComponent && <ModalFooter direction={'row'} paddings={'12px 12px 12px 24px'} justify={'flex-end'} align={'center'} flex={'0 0 auto'}>*/}
                    {/*    <item.footerComponent hideModal={() => handleHide(item.name)} {...item.modalProps} />*/}
                    {/*</ModalFooter>}*/}
                </ModalWrapper>
            ))}
            <Overlay />
        </Container>
    );
};

const ModalWrapper = styled.div`
  pointer-events: all;
  background-color: ${({theme}) => theme.colors.background};
  border-radius: ${({theme}) => theme.other.borderRadiusBig};
  width: ${({width}) => width}px;
  position: absolute;
  z-index: 1000;
  max-height: calc(100vh - 60px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 20px rgba(42,70,100, .4), 0 4px 4px rgba(42,70,100, .2);
`

const Title = styled.div`
  flex: 1 1 auto;
  ${({theme}) => theme.mixins.gothamBold({color: theme.colors.primary, size: '18px'})};
`

const Container = styled.div`
  display: ${({isVisible}) => isVisible ? 'flex' : 'none'};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 1000;
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(7, 32, 51, 0.24);
  z-index: 999;
  pointer-events: all;
`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 10px 0 20px;
  flex: 0 0 auto;
`

const ModalContent = styled.div`
    padding: 0 20px 20px 20px;
    max-height: 800px;
    overflow-y: auto;
`

const ModalFooter = styled.div`

`

export default Modal;