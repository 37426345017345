import { resetStorageVariables } from './auth';

const checkIfUnauthenticated = (responseJson) => {
  const isUnauthenticated = responseJson?.error?.messages?.[0] === 'Unauthenticated.';

  return isUnauthenticated;
};

export const fetchRequest = (url, params) => {
  const requestStatus = {
    error: null,
    success: false,
    response: null,
  };

  return new Promise(async (resolve) => {
    try {
      const response = await fetch(url, params);

      let responseJson = null;

      try {
        responseJson = await response.json();
      } catch (error) { }

      if (checkIfUnauthenticated(responseJson)) {
        resetStorageVariables();
        window.location.reload();
        return;
      }

      requestStatus.success = true;
      requestStatus.responseCode = response.status;
      requestStatus.response = responseJson;

    } catch (error) {
      console.log(new Error(`Произошла ошибка при отправке запроса [url]='${url}'`));
      console.log(error);

      requestStatus.error = error;
    }

    resolve(requestStatus);
  });
};