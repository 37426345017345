import { createSlice } from "@reduxjs/toolkit";

export const exportReducer = createSlice({
  name: 'export',
  initialState: {
    exportUrl: false,
    exportName: null,
    exportData: null,
  },
  reducers: {
    setExportState: (state, action) => {
      const { exportName, exportUrl, exportData } = action.payload;

      return {
        exportUrl,
        exportName,
        exportData,
      };
    },
  },
});

export const { setExportState } = exportReducer.actions;

export default exportReducer.reducer;
