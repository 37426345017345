import { createSlice } from '@reduxjs/toolkit';

export const statisticsReducer = createSlice({
    name: 'UP',
    initialState: {
        count: null,
        closedCount: null,
        type: ''
    },
    reducers: {
        setValues: (state, action) => {
            const { count, closedCount, type } = action.payload;

            return {
                count,
                closedCount,
                type,
            };
        },
        resetValues: () => {
            return {
                count: null,
                closedCount: null,
                type: ''
            };
        },
    },
});

export const { setValues, resetValues } = statisticsReducer.actions;

export default statisticsReducer.reducer;
