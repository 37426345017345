import { getUserConfigFontSizeFromStorage } from "./utils/auth";

export const USER_FONT_SIZE_CONST_SM = 'sm';
export const USER_FONT_SIZE_CONST_MD = 'md';
export const USER_FONT_SIZE_CONST_LG = 'lg';

export const theme = {
    elements: {
        headerHeight: '50px',
    },
    fonts: {
        family: {
            gothamRegular: "'GothamRegular', Arial, sans-serif",
            gothamSemibold: "'GothamSemibold', Arial, sans-serif",
            gothamBold: "'GothamBold', Arial, sans-serif",
        },
        sizes: {
            xs: '12px',
            sm: '14px',
            smd: '16px',
            md: '18px',
            lg: '22px',
        },
        lineHeight: {
            normal: '125%',
        }
    },
    colors: {
        white: '#fff',
        primary: '#37516D',
        secondary: '#579BE3',
        text: '#333333',
        textInvert: '#fff',
        border: 'rgba(0,0,0,.1)',
        background: '#fff',
        alert: '#C14F4F',
    },
    other: {
        borderRadiusBig: '10px',
        borderRadiusNormal: '6px',
        borderRadiusSmall: '4px',
    },
    transitions: {
        normal: '.3s ease-in-out',
    },
    mixins: {
        gothamRegular: ({size = theme.fonts.sizes[theme.userSettings.fontSize], color = theme.colors.text, lineheight = theme.fonts.lineHeight.normal} = {size: theme.fonts.sizes[theme.userSettings.fontSize], color: theme.colors.text, lineheight: theme.fonts.lineHeight.normal}) => `
            font-family: ${theme.fonts.family.gothamRegular};
            font-weight: normal;
            font-size: ${size};
            color: ${color};
            line-height: ${lineheight || (parseInt(size) * 1.5) + 'px'};
        `,
        gothamSemibold: ({size = theme.fonts.sizes[theme.userSettings.fontSize], color = theme.colors.text, lineheight = theme.fonts.lineHeight.normal} = {size: theme.fonts.sizes[theme.userSettings.fontSize], color: theme.colors.text, lineheight: theme.fonts.lineHeight.normal}) => `
            font-family: ${theme.fonts.family.gothamSemibold};
            font-size: ${size};
            color: ${color};
            line-height: ${lineheight || (parseInt(size) * 1.5) + 'px'};
        `,
        gothamBold: ({size = theme.fonts.sizes[theme.userSettings.fontSize], color = theme.colors.text, lineheight = theme.fonts.lineHeight.normal} = {size: theme.fonts.sizes[theme.userSettings.fontSize], color: theme.colors.text, lineheight: theme.fonts.lineHeight.normal}) => `
            font-family: ${theme.fonts.family.gothamBold};
            font-weight: normal;
            font-size: ${size};
            color: ${color};
            line-height: ${lineheight || (parseInt(size) * 1.5) + 'px'};
        `,
        borderRadius: (radius) => `
            border-radius: ${radius ? radius : theme.other.borderRadiusNormal};
        `,
        fontSize: (value) => `
            font-size: ${theme.fonts.sizes[value]};
            line-height: ${theme.fonts.sizes[value] * 1.5};
        `,
    },
    userSettings: {
        fontSize: getUserConfigFontSizeFromStorage() || USER_FONT_SIZE_CONST_SM,
    }
}