import { configureStore } from '@reduxjs/toolkit';
import modalReducer from '../features/modal/modalSlice';
import anchorPopupReducer from '../features/anchorPopup/anchorPopupSlice';
import appReducer from '../features/app/appSlice';
import exportReducer from '../features/export/exportReducer';
import statisticsReducer from '../features/statisticsReducer';
import breadcrumbsReducer from '../features/breadcrumbsReducer';

export const store = configureStore({
  reducer: {
    modal: modalReducer,
    anchorPopup: anchorPopupReducer,
    app: appReducer,
    export: exportReducer,
    statistics: statisticsReducer,
    breadcrumbs: breadcrumbsReducer,
  },
});
