import React, { createRef, useCallback, useEffect, useState } from 'react';
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { hide } from "../../features/anchorPopup/anchorPopupSlice";

const AnchorPopup = () => {
    const ref = createRef()
    const dispatch = useDispatch()

    const [posX, setPosX] = useState(0);
    const [posY, setPosY] = useState(0);

    const popup = useSelector(state => state.anchorPopup)


    const handleScroll = useCallback((e) => {
        dispatch(hide())
    }, [])

    useEffect(() => {
        window.addEventListener('wheel', handleScroll)
        return () => {
            window.removeEventListener('wheel', handleScroll)
        }
    }, [handleScroll])

    useEffect(() => {
        const calculatePositionX = (positionX, ref, positionType) => {
            if (positionType === 'centered') {
                return (window.innerWidth / 2) - (ref?.current?.getBoundingClientRect().width / 2)
            }
            if (positionX + ref?.current?.getBoundingClientRect().width > window.innerWidth) {
                return window.innerWidth - ref?.current?.getBoundingClientRect().width - 20
            }
            return positionX;
        }

        const calculatePositionY = (positionY, elHeight, ref) => {
            if (positionY + elHeight + ref?.current?.getBoundingClientRect().height > window.innerHeight) {
                return positionY - elHeight - ref?.current?.getBoundingClientRect().height
            }

            return positionY;
        }

        setPosX(calculatePositionX(popup?.props?.parentPosition?.x || popup?.elementPosition?.x, ref, popup?.positionType))
        setPosY(calculatePositionY(popup?.props?.parentPosition?.y || popup?.elementPosition?.y, popup?.elementPosition?.height, ref, popup?.positionType))
    }, [popup?.elementPosition?.height, popup?.elementPosition?.x, popup?.elementPosition?.y, popup?.props?.parentPosition?.x, popup?.props?.parentPosition?.y, ref, popup?.positionType])

    const renderContent = () => {
        if (popup.component) {
            return <popup.component handleHide={() => dispatch(hide())} {...popup.props} />
        }

        return null;
    }

    return (
        <AnchorPopupWrapper
            visible={popup?.visible}
            positionX={popup?.elementPosition?.x}
            positionY={popup?.elementPosition?.y}
            elementHeight={popup?.elementPosition?.height}
            elementWidth={popup?.elementPosition?.width}
            parentX={popup?.props?.parentPosition?.x}
            parentY={popup?.props?.parentPosition?.y}
            posX={posX}
            posY={posY}
            ref={ref}
            width={popup?.props?.width}
            height={popup?.props?.height}
            type={popup?.props?.type}
            namePopup={popup?.props?.namePopup}
            onClick={e => e.stopPropagation()}
        >
            {renderContent()}
        </AnchorPopupWrapper>
    );
};

const AnchorPopupWrapper = styled.div`
  position: absolute;
  display: ${({ visible, posX, positionY }) => visible && posX && positionY ? 'block' : 'none'};
  left: ${({ posX }) => posX}px;
  // left: ${({ posX, parentX }) => parentX ? parentX : posX}px;
  top: ${({ posY, elementHeight }) => posY + elementHeight}px;
  max-height: ${({ height }) => height};
  // top: ${({ posY, elementHeight, parentY }) => parentY ? parentY + elementHeight + 8 : posY + elementHeight + 8}px;
  z-index: 1000;
  width: ${({ width }) => width}px;
`

export default AnchorPopup;