import { shipPropertiesTableFields } from '../components/ContractCharacteristics/components';
import { addFilter } from './addFilter';
import { addTableFields } from './addTableFields';

// Всегда константное для любого пользователя
export const CLIENT_ID = 1;

// Всегда константное для любого пользователя
export const CLIENT_SECRET = 'c75IGwuqkjrO1RWCE4Ntn4zqpQdpgnEO2wGT9iMT';

export const GRANT_TYPE = 'password';

export const SERVER_HOSTNAME = process.env.REACT_APP_SERVER_ADDRESS || 'http://server.clerc.ru/';

export const API_URL_LOGIN = SERVER_HOSTNAME + 'api/v2.0/auth/login';
export const API_URL_LOGOUT = SERVER_HOSTNAME + 'api/v2.0/auth/logout';

export const API_URL_FILES = SERVER_HOSTNAME + 'api/v2.0/files';
export const API_URL_LOAD_FILES = SERVER_HOSTNAME + 'api/v2.0/files/bulkStore';

export const API_URL_USERS = SERVER_HOSTNAME + 'api/v2.0/users?order=position';
export const API_URL_USERS_PROFILE = SERVER_HOSTNAME + 'api/v2.0/users/profile';

export const API_URL_USERS_SET_POSITIONS = SERVER_HOSTNAME + 'api/v2.0/users/setPositions';
export const API_getUserEditAndDeleteUrl = userId => SERVER_HOSTNAME + 'api/v2.0/users/' + userId;

export const API_URL_CONTRACTS = SERVER_HOSTNAME + 'api/v2.0/contracts?order=position';

export const API_getContractEditAndDeleteUrl = contractId => SERVER_HOSTNAME + 'api/v2.0/contracts/' + contractId;

export const API_URL_SHIP_PROPERTIES_SET_POSITIONS = SERVER_HOSTNAME + 'api/v2.0/shipProperties/setPositions';
export const API_getShipPropertiesEditAndDeleteUrl = propertyId => SERVER_HOSTNAME + 'api/v2.0/shipProperties/' + propertyId;

export const API_URL_BANK_GUARANTESS_SET_POSITIONS = SERVER_HOSTNAME + 'api/v2.0/bankGuarantees/setPositions';
export const API_getBankGuaranteesEditAndDeleteUrl = propertyId => SERVER_HOSTNAME + 'api/v2.0/bankGuarantees/' + propertyId;

export const API_URL_CONTRACT_OBJECTS_SET_POSITIONS = SERVER_HOSTNAME + 'api/v2.0/contractObjects/setPositions';
export const API_getContractObjectsEditAndDeleteUrl = propertyId => SERVER_HOSTNAME + 'api/v2.0/contractObjects/' + propertyId;

export const API_URL_PAYMENT_STAGES_SET_POSITIONS = SERVER_HOSTNAME + 'api/v2.0/paymentStages/setPositions';
export const API_getPaymentStagesEditAndDeleteUrl = propertyId => SERVER_HOSTNAME + 'api/v2.0/paymentStages/' + propertyId;

export const API_URL_KEY_EVENT_SET_POSITIONS = SERVER_HOSTNAME + 'api/v2.0/keyEvents/setPositions';
export const API_getKeyEventsEditAndDeleteUrl = propertyId => SERVER_HOSTNAME + 'api/v2.0/keyEvents/' + propertyId;

export const API_URL_CLAIM_WORKS_SET_POSITIONS = SERVER_HOSTNAME + 'api/v2.0/claimWorks/setPositions';
export const API_getClaimWorksEditAndDeleteUrl = propertyId => SERVER_HOSTNAME + 'api/v2.0/claimWorks/' + propertyId;

export const API_URL_GET_JUDICAL_WORKS_SET_POSITIONS = SERVER_HOSTNAME + 'api/v2.0/judicialWorks/setPositions';
export const API_getJudicialWorksEditAndDeleteUrl = propertyId => SERVER_HOSTNAME + 'api/v2.0/judicialWorks/' + propertyId;

export const API_URL_GET_CASH_EXECUTIONS_SET_POSITIONS = SERVER_HOSTNAME + 'api/v2.0/cashExecutions/setPositions';
export const API_getCashExecutionsEditAndDeleteUrl = propertyId => SERVER_HOSTNAME + 'api/v2.0/cashExecutions/' + propertyId;

export const API_getLimitBudgetaryObligationsEditAndDeleteUrl = propertyId => SERVER_HOSTNAME + 'api/v2.0/limitBudgetaryObligations/' + propertyId;

export const API_URL_GET_ABOUT_OBJECT_SET_POSITIONS = SERVER_HOSTNAME + 'api/v2.0/aboutObjects/setPositions';
export const API_getAboutObjectEditAndDeleteUrl = propertyId => SERVER_HOSTNAME + 'api/v2.0/aboutObjects/' + propertyId;

export const API_getContractObjectsEditUrl = objectId => SERVER_HOSTNAME + 'api/v2.0/contractObjects/' + objectId;

export const API_OBJECT_WATHERS_EXPORT_URL = SERVER_HOSTNAME + 'api/v2.0/users/watchersExport';

export const API_URL_GET_MOORING_CERTIFICATES_SET_POSITIONS = SERVER_HOSTNAME + 'api/v2.0/mooringCertificates/setPositions';
export const API_getObjectMooringCertificatesEditAndDeleteUrl = propertyId => SERVER_HOSTNAME + 'api/v2.0/mooringCertificates/' + propertyId;

export const API_URL_GET_TRAVELING_CERTIFICATES_SET_POSITIONS = SERVER_HOSTNAME + 'api/v2.0/travelingCertificates/setPositions';
export const API_getObjectTravelingCertificatesEditAndDeleteUrl = propertyId => SERVER_HOSTNAME + 'api/v2.0/travelingCertificates/' + propertyId;

export const API_getGroupsEditAndDeleteUrl = propertyId => SERVER_HOSTNAME + 'api/v2.0/groups/' + propertyId;

export const API_getGroupConstructionCertificates = groupId => SERVER_HOSTNAME + 'api/v2.0/groups/' + groupId +
  '?with[relationships][construction_certificates]' +
  '&order=number';

export const API_URL_GET_CONSTRUCTION_CERTIFICATES_SET_POSITIONS = SERVER_HOSTNAME + 'api/v2.0/constructionCertificates/setPositions';
export const API_getConstructionCertificatesEditAndDeleteUrl = propertyId => SERVER_HOSTNAME + 'api/v2.0/constructionCertificates/' + propertyId;

export const API_URL_GET_REPORTS_SET_POSITIONS = SERVER_HOSTNAME + 'api/v2.0/reports/setPositions';
export const API_getReportsEditAndDeleteUrl = propertyId => SERVER_HOSTNAME + 'api/v2.0/reports/' + propertyId;

export const API_getShipProperties = filter => SERVER_HOSTNAME + 'api/v2.0/shipProperties' +
  '?order=position' +
  addFilter(filter);

export const API_getShipPropertiesExport = filter => API_getShipProperties(filter) +
  '&return_type=xlsx' +
  addTableFields(shipPropertiesTableFields);

export const API_getBankGuarantees = filter => SERVER_HOSTNAME + 'api/v2.0/bankGuarantees' +
  '?order=position' +
  addFilter(filter);

export const API_getLimitBudgetaryObligations = filter => SERVER_HOSTNAME + 'api/v2.0/limitBudgetaryObligations' +
  '?order=position' +
  addFilter(filter);

export const API_getContractObjects = filter => SERVER_HOSTNAME + 'api/v2.0/contractObjects' +
  '?order=position' +
  addFilter(filter);

export const API_getPaymentStages = filter => SERVER_HOSTNAME + 'api/v2.0/paymentStages' +
  '?order=position' +
  addFilter(filter);

export const API_getKeyEvents = filter => SERVER_HOSTNAME + 'api/v2.0/keyEvents' +
  '?order=position' +
  addFilter(filter);

export const API_getClaimWorks = filter => SERVER_HOSTNAME + 'api/v2.0/claimWorks' +
  '?order=position' +
  addFilter(filter);

export const API_getJudicialWorks = filter => SERVER_HOSTNAME + 'api/v2.0/judicialWorks' +
  '?order=position' +
  addFilter(filter);

export const API_getCashExecutions = filter => SERVER_HOSTNAME + 'api/v2.0/cashExecutions' +
  '?order=position' +
  addFilter(filter);

export const API_getDeveloping = contractId => SERVER_HOSTNAME + `api/v2.0/contracts/${contractId}/familiarization` +
  '?order=position';

export const API_getAboutObject = filter => SERVER_HOSTNAME + `api/v2.0/aboutObjects` +
  '?order=position' +
  addFilter(filter);

export const API_getObjectMooringCertificates = filter => SERVER_HOSTNAME + `api/v2.0/mooringCertificates` +
  '?order=number' +
  '&with[relationships][files]' +
  '&with[relationships][presentations]' +
  addFilter(filter);

export const API_getObjectTravelingCertificates = filter => SERVER_HOSTNAME + `api/v2.0/travelingCertificates` +
  '?order=number' +
  '&with[relationships][files]' +
  '&with[relationships][presentations]' +
  addFilter(filter);

export const API_getObjectWatchers = (objectId, filter) => SERVER_HOSTNAME + `api/v2.0/contractObjects/${objectId}` +
  '?order=position' +
  addFilter(filter);

export const API_getGroups = (filter) => SERVER_HOSTNAME + `api/v2.0/groups` +
  '?order=position' +
  '&with[relationships][contract_object]' +
  addFilter(filter);

export const API_URL_GROUPS_SET_POSITIONS = SERVER_HOSTNAME + `api/v2.0/groups/setPositions`;

export const API_getConstructionCertificates = (filter) => SERVER_HOSTNAME + `api/v2.0/constructionCertificates` +
  '?order=number' +
  addFilter(filter);

export const API_getReports = (filter) => SERVER_HOSTNAME + `api/v2.0/reports` +
  '?order=number' +
  addFilter(filter);

export const API_getPresentations = ({ order = '-position', filter } = {}) => SERVER_HOSTNAME + 'api/v2.0/presentations' +
  '?order=' + order +
  addFilter(filter);

export const API_getPresentationsBulk = ({ order = '-position', filter } = {}) => SERVER_HOSTNAME + 'api/v2.0/presentations/bulkStore' +
  '?order=' + order +
  addFilter(filter);