export const addFilter = (filterArray) => {
  if (!filterArray) return '';

  return filterArray.map(({ column, operator, value, booleanType }, i) => {
    const booleanString = booleanType ? `&filter[${i}][boolean]=${booleanType}` : '';

    const filterString = '' +
      `&filter[${i}][column]=${column}` +
      `&filter[${i}][operator]=${operator}` +
      `&filter[${i}][value]=${value}` +
      booleanString;

    return filterString;
  }).join('');
};