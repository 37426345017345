import {createSlice} from "@reduxjs/toolkit";

export const modalSlice = createSlice({
  name: 'modal',
  initialState: [],
  reducers: {
    show: (state, action) => {
      return state.map(item => item.name === action.payload ? {...item, isOpen: true} : {...item, isOpen: false})
    },
    hide: (state, action) => {
      return state.map(item => item.name === action.payload ? {...item, isOpen: false} : {...item, isOpen: false})
    },
    registerModal: ((state, action) => {
      return [
        ...state,
        {
          name: action.payload.name,
          isOpen: false,
          component: action.payload.component,
          title: action.payload.title,
          data: action.payload.data,
          props: action.payload.props,
        }
      ]
    }),
    unregisterModal: ((state, action) => {
      return state.filter(item => item.name !== action.payload)
    })
  },
});

export const { show, hide, registerModal, unregisterModal } = modalSlice.actions;

export default modalSlice.reducer;
