import { createSlice } from '@reduxjs/toolkit';

export const anchorPopupSlice = createSlice({
    name: 'anchorPopup',
    initialState: {
        visible: false,
        component: null,
        elementPosition: null,
        props: null,
    },
    reducers: {
        show: (state, action) => {
            return {
                visible: true,
                component: action.payload.component,
                positionType: action.payload.positionType,
                elementPosition: action.payload.elementPosition,
                props: action.payload.props,
            }
        },
        hide: (state, action) => {
            return {
                visible: false,
                component: null,
                elementPosition: null,
                props: null
            }
        },
    },
});

export const { show, hide } = anchorPopupSlice.actions;
export const anchorPopupActions = anchorPopupSlice.actions;

export default anchorPopupSlice.reducer;
