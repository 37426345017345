import { API_URL_LOGOUT } from "./api";
import { decoder, encoder } from "./crypto";
import { fetchRequest } from "./fetchRequest";

const IS_AUTH_PARAM = 'auth';
const ACCESS_TOKEN_PARAM = 'accessToken';
const TOKEN_TYPE_PARAM = 'tokenType';
const USER_ID_PARAM = 'userId';
const USER_CONFIG_FONT_SIZE_PARAM = 'userFontSize';
const USER_ROLE_PARAM = 'userRole';

export const checkIsAuthorized = () => {
  const auth = decoder(localStorage.getItem(IS_AUTH_PARAM));
  return auth === 'true';
};

export const checkIsAdminRole = () => {
  return getUserRoleFromStorage() === 1;
};

export const checkIsWatcherRole = () => {
  return getUserRoleFromStorage() === 0;
};

export const autorizeUser = ({ accessToken, tokenType }) => {
  localStorage.setItem(IS_AUTH_PARAM, encoder(true));

  localStorage.setItem(ACCESS_TOKEN_PARAM, encoder(accessToken));
  localStorage.setItem(TOKEN_TYPE_PARAM, encoder(tokenType));
};

export const saveUserIdToStorage = (userId) => {
  if (!userId) return;

  localStorage.setItem(USER_ID_PARAM, encoder(userId));
};

export const getUserIdFromStorage = () => {
  return decoder(localStorage.getItem(USER_ID_PARAM));
};

export const saveUserConfigFontSizeToStorage = (fontSize) => {
  if (!fontSize) return;

  localStorage.setItem(USER_CONFIG_FONT_SIZE_PARAM, fontSize);
};

export const getUserConfigFontSizeFromStorage = () => {
  return localStorage.getItem(USER_CONFIG_FONT_SIZE_PARAM);
};

export const resetStorageVariables = () => {
  Object.keys(localStorage).forEach(key => {
    localStorage.removeItem(key);
  });
};

export const saveUserRoleToStorage = (userRole) => {
  localStorage.setItem(USER_ROLE_PARAM, encoder(userRole));
};

export const getUserRoleFromStorage = () => {
  return Number(decoder(localStorage.getItem(USER_ROLE_PARAM)));
};

export const logoutUser = async () => {
  const options = {
    method: 'POST',
    headers: {
      'Authorization': getAuthorizationHeader(),
    },
  };

  await fetchRequest(API_URL_LOGOUT, options);

  resetStorageVariables();
};

export const getAuthorizationHeader = () => {
  const type = decoder(localStorage.getItem(TOKEN_TYPE_PARAM));
  const token = decoder(localStorage.getItem(ACCESS_TOKEN_PARAM));

  return type + ' ' + token;
};