import { createSlice } from '@reduxjs/toolkit';
import { USER_FONT_SIZE_CONST_SM } from '../../theme';
import { getUserConfigFontSizeFromStorage } from '../../utils/auth';

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        sideMenu: true,
        fontSize: getUserConfigFontSizeFromStorage() || USER_FONT_SIZE_CONST_SM,
    },
    reducers: {
        toggleGroupsPanel: (state, action) => {
            return {
                ...state,
                sideMenu: !state.sideMenu,
            }
        },
        changeUserFontSize: (state, action) => {
            return {
                ...state,
                fontSize: action.payload,
            }
        }
    },
});

export const { toggleGroupsPanel, changeUserFontSize } = appSlice.actions;
export const appActions = appSlice.actions;

export default appSlice.reducer;
