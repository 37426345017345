const KEY_CONTRACT_ID = 'contractId';
const KEY_CONTRACT_NAME = 'contractName';
const KEY_CONTRACT_NUMBER = 'contractNumber';
const KEY_BUILDING_NUMBER = 'buildingNumber';
const KEY_OBJECT_ID = 'objectId';

export const saveContractIdToStorage = (contractId) => {
  localStorage.setItem(KEY_CONTRACT_ID, contractId);
};

export const getContractIdFromStorage = () => {
  return Number(localStorage.getItem(KEY_CONTRACT_ID));
};

export const saveContractNameToStorage = (contractName) => {
  localStorage.setItem(KEY_CONTRACT_NAME, contractName);
};

export const getContractNameFromStorage = () => {
  return localStorage.getItem(KEY_CONTRACT_NAME);
};

export const saveContractNumberToStorage = (contractNumber) => {
  localStorage.setItem(KEY_CONTRACT_NUMBER, contractNumber);
};

export const getContractNumberFromStorage = () => {
  return localStorage.getItem(KEY_CONTRACT_NUMBER);
};

export const saveObjectIdToStorage = (objectId) => {
  localStorage.setItem(KEY_OBJECT_ID, objectId);
};

export const getObjectIdFromStorage = () => {
  return Number(localStorage.getItem(KEY_OBJECT_ID));
};

export const saveBuildingNumberToStorage = (buildingNumber) => {
  localStorage.setItem(KEY_BUILDING_NUMBER, buildingNumber);
};

export const getBuildingNumberFromStorage = () => {
  return Number(localStorage.getItem(KEY_BUILDING_NUMBER));
};