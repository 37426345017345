import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.12169 -0.00388867L0.000366211 2.11743L27.4997 29.6168L29.6211 27.4955L2.12169 -0.00388867Z"/>
            <path d="M27.4997 0.00330373L0.000366211 27.5027L2.12169 29.624L29.6211 2.12462L27.4997 0.00330373Z"/>
        </svg>
    );
};

CloseIcon.propTypes = {

};

export default CloseIcon;