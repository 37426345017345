import * as dotenv from 'dotenv';
import React from 'react';
import ReactDOM from 'react-dom';
import { store } from './app/store';
import {Provider} from 'react-redux';
import {GlobalStyle} from "./GlobalStyle";
import AppWrapper from "./components/AppWrapper";

dotenv.config();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <GlobalStyle />
        <AppWrapper />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);