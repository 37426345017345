import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const Icon = ({icon, onClick, color, size, padding}) => {

    const Icon = icon

    return (
        <IconContainer onClick={onClick && onClick} color={color} size={size} padding={padding}>
            <Icon />
        </IconContainer>
    );
};

const IconContainer = styled.div`
    width: ${({size}) => size}px;
    height: ${({size}) => size}px;
    padding: ${({padding}) => padding}px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: .4;
    transition: opacity ${({theme}) => theme.transitions.normal};
    svg {
        fill: ${({theme, color}) => theme.colors[color]};
    }
    :hover {
        opacity: 1;
    }
`

Icon.propTypes = {
    icon: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    color: PropTypes.oneOf(['white', 'primary']),
    size: PropTypes.number,
    padding: PropTypes.number,
};

Icon.defaultProps = {
    color: 'primary',
    size: 40,
    padding: 10,
}

export default Icon;