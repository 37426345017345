import React, { useCallback, useEffect } from 'react';
import { theme } from "../../theme";
import App from "../../App";
import { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { hide } from "../../features/anchorPopup/anchorPopupSlice";

const AppWrapper = () => {
    const dispatch = useDispatch();
    const app = useSelector(state => state.app);

    const handlePopupHide = useCallback(() => {
        dispatch(hide())
    }, []);

    useEffect(() => {
        document.body.addEventListener('click', handlePopupHide)
        window.addEventListener('resize', handlePopupHide);

        return () => {
            window.removeEventListener('resize', handlePopupHide)
            document.body.removeEventListener('click', handlePopupHide)
        }
    }, [handlePopupHide]);

    return (
        <ThemeProvider theme={{ ...theme, userSettings: { fontSize: app.fontSize } }} >
            <App />
        </ThemeProvider >
    );
};

export default AppWrapper;