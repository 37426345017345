import SearchIcon from '../Icons/SearchIcon';

export const shipPropertiesTableFields = [
  {
    id: 0,
    name: 'key',
    label: 'Наименование',
    props: {
      width: '50%',
    },
  },
  {
    id: 1,
    name: 'value',
    label: 'Значение',
  },
];

export const searchIcons = [
  {
    id: 0,
    component: SearchIcon,
  },
];
