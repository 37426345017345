// '&export[email]=Логин/e-mail' +
// '&export[fullname]=ФИО' +
// '&export[role]=Роль' +
// '&export[company]=Примечание';

export const addTableFields = (tableFields = []) => {
  return tableFields.reduce((result, tableField) => {
    const { name: propertyName, label: propertyValue, withoutPrefix } = tableField;

    const stringValue = withoutPrefix ? `&${propertyName}=${propertyValue}` : `&export[${propertyName}]=${propertyValue}`;

    result += stringValue;

    return result;
  }, '');
};