import React from 'react';

const SearchIcon = () => {
    return (
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 22C8.82441 22 6.69767 21.3549 4.88873 20.1462C3.07979 18.9375 1.66989 17.2195 0.83733 15.2095C0.00476617 13.1995 -0.213071 10.9878 0.211367 8.85401C0.635804 6.72022 1.68345 4.76021 3.22183 3.22183C4.76021 1.68345 6.72022 0.635804 8.85401 0.211367C10.9878 -0.213071 13.1995 0.00476617 15.2095 0.83733C17.2195 1.66989 18.9375 3.07979 20.1462 4.88873C21.3549 6.69767 22 8.82441 22 11C22 13.9174 20.8411 16.7153 18.7782 18.7782C16.7153 20.8411 13.9174 22 11 22ZM11 3.00001C9.41775 3.00001 7.87104 3.4692 6.55544 4.34825C5.23985 5.2273 4.21447 6.47673 3.60897 7.93854C3.00347 9.40035 2.84504 11.0089 3.15372 12.5607C3.4624 14.1126 4.22433 15.538 5.34315 16.6569C6.46197 17.7757 7.88743 18.5376 9.43928 18.8463C10.9911 19.155 12.5997 18.9965 14.0615 18.391C15.5233 17.7855 16.7727 16.7602 17.6518 15.4446C18.5308 14.129 19 12.5823 19 11C19 8.87827 18.1572 6.84344 16.6569 5.34315C15.1566 3.84286 13.1217 3.00001 11 3.00001Z" />
            <path d="M18.5635 16.4404L16.4421 18.5618L27.9397 30.0593L30.061 27.938L18.5635 16.4404Z" />
        </svg>
    );
};

export default SearchIcon;