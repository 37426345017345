import {createGlobalStyle} from "styled-components";

export const GlobalStyle = createGlobalStyle`
    * {
      box-sizing: border-box;
    }

    .body_loading,
    .body_loading *:hover {
      cursor: wait !important;
    }
    
    body, html {
      padding: 0;
      margin: 0;
      min-height: 100%;
      height: 100%;
      font-family: 'GothamRegular', Arial, sans-serif;
      overflow: hidden;
    }
    
    a {
      text-decoration: none;
      color: inherit;
    }
    
    #root {
      height: 100%;
      width: 100%;
      display: flex;
    }

    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    ::-webkit-scrollbar-track {
      
    }

    ::-webkit-scrollbar-thumb {
      background-color: #37516D;
      border-radius: 4px;
    }
    
`